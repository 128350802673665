.App {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

textarea{
    padding-left: 5px;
    font-size: 20px;
    height: 200px;
    width: 800px;
    max-width: 800px;
    max-height: 200px;
    max-lines: 6;
    padding-bottom: 20;
    padding: auto;
    }

button{
    padding-top: 10;
    width: 100px;
    height: 30px;
    font-weight: bold;
    font-size: 20px;
    border: 2px solid forestgreen;
    background-color: rgb(226, 252, 184);
    cursor: pointer;
    align-items: center;
    }
    
.buttond{
    align-items: center;
    padding-top: 20;
}

.container{
    height: 200px;
    width: 800px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    }
p{
    font-size: 30px;
    }